import Link from "next/link";
import { useAnniversaryTimer } from "./useAnniversaryTimer";

const AnniversaryTimer = (props) => {
    const { duration, timerMsg, styles, websiteID, isMobileType, timerHomeImgUrl, timerHomeMobImg, timerHomeDeskImg, timerHomeFont } = props
    const { timer } = useAnniversaryTimer({ duration });

    const linkHref = timerHomeImgUrl ? (websiteID === 1 ? timerHomeImgUrl : `international/${timerHomeImgUrl}`) : '/';

    return (
        <Link href={linkHref}>
            <div className={styles.anniversary_sec12} >
                <img className={styles.anniversaryBgImg}
                    src={isMobileType ? timerHomeMobImg : timerHomeDeskImg}
                    alt='Timer background img'
                    loading="eager"
                    layout="responsive" />
                <h2>
                    <abbr style={{ fontFamily: timerHomeFont }}>{timerMsg}</abbr>
                </h2>
                <span className={styles.a_sec12_4}>{timer?.date}</span>:
                <span>{timer?.hour}</span>:<span className={styles.a_sec12_2}>{timer?.min}</span>:<span className={styles.a_sec12_3}>{timer?.sec}</span>
            </div>
        </Link>
    );
}

export default AnniversaryTimer